<template>
  <div class="login-box">
    <!-- <canvas id="myCanvas" ref="myCanvas" width="650" height="1198" style="position: absolute;background:red"></canvas> -->
    <div class="login-container">
      <div class="title">{{ projectConfig.name }}</div>
      <div class="title_under_line"></div>
      <div class="input-container">
        <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
          <el-form-item style="width: 150px" prop="accountNumberAndPhone">
            <el-input
              v-model.trim="ruleForm.accountNumberAndPhone"
              placeholder="账号"
              style="width: 350px"
              maxlength="50"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item style="width: 150px" prop="pwd">
            <el-input
              v-model.trim="ruleForm.pwd"
              placeholder="密码"
              style="width: 350px"
              type="password"
              maxlength="50"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item style="width: 150px" prop="verification">
            <el-input
              v-model.trim="ruleForm.verification"
              placeholder="验证码"
              style="width: 350px"
              maxlength="4"
              clearable
            ></el-input>
            <!-- 图形验证码框 -->
            <div class="form-imageCode" @click="getImgCode">
              <img :src="imageCode" alt="" />
            </div>
          </el-form-item>
        </el-form>
        <!-- <div class="login-button" @click="goToLogin" :disabled="isdisabled" >登录</div> -->
        <el-button
          class="login-button"
          type="primary"
          :loading="loginBtnDisabled"
          :disabled="loginBtnDisabled"
          @click="goToLogin"
          >登录</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { login, getCode } from "../../api/login_v1";
import store from "@/base/store";
import { projectConfig } from "@/commonConfig/project.js";
export default {
  data() {
    return {
      projectConfig,
      imageCode: "", //图形验证码值
      loginBtnDisabled: false,
      ruleForm: {
        imageCode: "", //
        accountNumberAndPhone: "", //
        pwd: "", //
        uuid: "",
        visitType: 1, //是否为web端
        permissionType: 1, //后台管理端
        accountType: "0", //0:用户名登录，1:手机号登录, 2:手机验证码登录
      },
      rules: {
        accountNumberAndPhone: [
          {
            required: true,
            message: "账号",
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: "密码",
            trigger: "blur",
          },
        ],
        verification: [
          {
            required: true,
            message: "验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    window.addEventListener("keydown", this.handleKeyDown, true); //开启监听键盘按下事件
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },

  mounted() {
    this.getImgCode();
    // this.recateImage();
  },
  methods: {
    recateImage() {
      console.log(document.getElementById("myCanvas"));
      var c = this.$refs.myCanvas;
      var ctx = c.getContext("2d");
      var point1 = { left: 172, top: 276 }; //第一个点
      var point2 = { left: 254, top: 236 }; //第二个点
      var point3 = { left: 354, top: 436 }; //第三个点
      let pointList = [];
      pointList.push(point1);
      pointList.push(point2);
      pointList.push(point3);
      pointList.push({ left: 374, top: 236 });
      if (pointList.length > 1) {
        ctx.beginPath();
        ctx.strokeStyle = "blue";
        ctx.moveTo(pointList[0].left, pointList[0].top); //起始位置
        for (var i = 1; i < pointList.length; i++) {
          console.log(pointList[i]);
          ctx.lineTo(pointList[i].left, pointList[i].top); //停止位置
        }
        ctx.stroke();
      }
    },

    handleKeyDown() {
      let key = null;
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        //触发的事件
        this.goToLogin();
      }
    },
    /* 获取图片验证码 */
    getImgCode() {
      let uuid = uuidv4();
      this.ruleForm.uuid = uuid;
      getCode({
        uuid: uuid,
      }).then((res) => {
        if (res.code == 1) {
          this.imageCode = res.data.imageCode;
        } else {
          this.$message({
            message: res.msg,
            type: "error",
            duration: 1000,
          });
        }
      });
    },
    goToLogin() {
      //

      this.$refs["ruleForm"].validate((volid) => {
        if (volid) {
          this.loginBtnDisabled = true;
          login(this.ruleForm)
            .then((res) => {
              this.loginBtnDisabled = false;
              if (res.code == 1) {
                let data = res.data;

                this.$store.commit("access_token", data.sessionId);
                sessionStorage.setItem(
                  "userInfo",
                  JSON.stringify(data.userInfo)
                );
                sessionStorage.setItem(
                  "loginUserMenu",
                  JSON.stringify(data.loginUserMenu)
                );
                // localStorage.setItem("imageRootPath", data.imageRootPath);
                let redirect = this.$route.query.redirect;
                if (redirect) {
                  console.log(redirect);
                  this.$router.push(redirect);
                } else {
                  this.$router.push("/home");
                }
              } else {
                console.log(res.code);
                //用户不存在
                if (res.code == 100003) {
                  this.$message({
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                  this.ruleForm.accountNumberAndPhone = "";
                  this.ruleForm.pwd = "";
                  //密码错误
                } else if (res.code == 100004) {
                  this.$message({
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                  this.ruleForm.pwd = "";
                } //验证码错误
                else if (res.code == 100002) {
                  this.$message({
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                } else {
                  this.$message({
                    message: res.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
                // this.$message.error(res.msg);
                /* 登录失败重新刷新验证码 */
                this.getImgCode();
                this.ruleForm.verification = "";
              }
            })
            .catch((e) => {
              this.loginBtnDisabled = false;
              this.getImgCode();
              this.ruleForm.verification = "";
            });
          this.$refs["ruleForm"].clearValidate;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-box {
  position: fixed;
  _position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 1190px;
  z-index: -10;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  zoom: 1;
  background-color: rgb(64, 64, 64);
  background-image: url(../../assets/login1.png);

  .login-container {
    width: 440px;
    height: 510px;
    background: #ffffff;
    box-shadow: -7px 7px 15px 0px rgba(0, 145, 85, 0.15);
    border-radius: 6px;
    position: absolute;
    right: 5%;
    top: 10%;
    .title_under_line {
      width: 30px;
      height: 2px;
      background: #9da0a5;
      margin: 20px auto;
    }
    .title {
      height: 27px;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #222222;
      line-height: 2px;
      text-align: center;
      margin-top: 62px;
    }
    .input-container {
      width: 330px;
      margin: 40px auto;
      .form-imageCode {
        width: 100px;
        height: 40px;
        position: absolute;
        top: -10px;
        left: 250px;
        border-radius: 5%;

        img {
          width: 100%;
          height: 100%;
        }
      }
      /deep/.el-input__inner {
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 0 10px;

        // &:visited {
        //   border-color: #e5faf1;
        //   background: transparent;
        // }
      }
      /deep/.el-input__inner:focus {
        border-color: #dcdfe6;
        // background: transparent;
      }
      /deep/.el-form-item__content {
        margin-top: 15px;
      }
    }
    .login-button {
      width: 330px;
      height: 46px;
      border-radius: 6px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
    }
  }
}
</style>
