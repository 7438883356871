import request from "@/base/util/request.js";


export const login = (data) => {
    return request({
        url: "/com/user/backstage/login",
        method: "post",
        data,
    })
}

// GET /com/user/getCode
// 获取验证码图片
export const getCode = (params) => {
    return request({
        url: "/com/user/getCode",
        method: "get",
        params,
    })
}

// POST /com/user/updatePassword
// 修改密码
export const updatePassword = (data) => {
    return request({
        url: "/com/user/updatePassword",
        method: "post",
        data,
    })
}